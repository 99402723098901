export const environment = {
	production: true,
	clientId: 'c71f4a5b-8685-4322-8d14-603eb313d14f', // USE SPA AZURE AD CLIENTID
	authority:
		'https://legendaryorg.b2clogin.com/legendaryorg.onmicrosoft.com/B2C_1A_AAD_LINK_SIGNUPORSIGNIN_MAXSIP',
	authorityDomain: 'legendaryorg.b2clogin.com',
	redirectUri: 'https://dev.maxsipapps.com',
	endpoint: 'https://dev.maxsipapps.com/api',
	endpointHealthCheck: 'https://dev.maxsipapps.com/api/health-ui',
	endpointScopes: ['https://legendaryorg.onmicrosoft.com/api/All'],
	inviteBaseUrl: 'https://legendaryorg.b2clogin.com/legendaryorg.onmicrosoft.com',
	inviteClientId: 'f429ec8c-eb5b-4f79-a13f-4a1952bab1b4', // AZURE AD B2C OIDC CLIENT ID
	geoCoding: {
		mapboxToken: 'pk.eyJ1Ijoic2FwbWF4c2lwdGVsIiwiYSI6ImNsdjQ5ODQ5OTA2aW4ya213bGJ2YzBlMzkifQ.HjoKwpjsaNbWN1uPEroVFA',
		apiUrl: 'https://api.mapbox.com/search/geocode/v6/forward'
	},
	version: 'maxsip-dev@1.0.47',
	stage: 'development',
	logRocketAppId: '',
	sessionRecordingMode: '',
	sentryDsn:
		'https://c714be368e3ec7b08390388c828afc12@o4506319083208704.ingest.sentry.io/4506319946842112',
	scandit: {
		//Trial Key Expires: 2023-12-27 18:27
		licenseKey: 'AV703DhzHCEAO8A6UQFjY2kQyTwrDPsA+mJNTgd4098Va6WKmB7zkBJbe0bnFXG1/Wlf7g4K8RfNb8ikaW5TsqpCd1kmLm8ABXGmLNdMvICYcKmJ31bhI4VaYlHvAxYaqA+IOioHqoATY2Fm3GLMLPtPowAqFlD6zFob5NtVyOJgY32wJ0GjVTBKkK4NXEUIelOWx41easIhaI/pBWXre317klmSea8FQFMuOPkhh7YFfjczTy+J2vkxk/omSeftoXxhjph+X45VXejLegWWWvlLPdeBTQB/BSjNLZ8mAcEqR3af1ytuWs8jd2BiQf8a0EivZiB3pIyJOQpgHnkzSt53pfNObtd2uVY5RSxNrTj1eBEeME8w/0tjQIj8YtbEfDLDv7VczHqeTWPoNyihkhhdcWrUa807jz48U+5/wYN/a/CUsH1jm1RgwVx9AzAtunbIIC955S64e8k8I3y2C/UvxWTaENTYniOkwNbDdapnv5DYos5+971f+dHprMy9aKPFk8KUKeLGjK9K9gKlOJ0Ltkm5rPds4ayqQUZuOK3h2u9WeRnd1i0Bfu0fmG8rRU6aei/puYcKc8dzG1pv0IOv3Yr94nuS+2hGb7mAH1ufXFXt0lrKHkqIgMT62lHG7NdGQLCfTdfwNmW3vTaqzVAZf5nOCv4YdjrWMuWVlyI9lFKsdnoFN3koLlR8eCiU5mJLa7lI4wGxf8XVV80/q6dovmf7wnnkY2RfVg4Mn+nO/lF6cgu5gF6MxZp/W4Zp3wpSTSepjaZhHgtTEXbTGNcVFdoxeKejS9vx5qYNFx8RBVGr4tRpmCrMvX7HUV3ht/msnVujLaBSOzRpH/xYETSsx+iQkbhxtNvnOQTuuA3pG26da6i7DYsaHZbqyNrBfCNulDblvo2Q/eXlNMtg+OAMCtTBu2NTXoGCWtnRlbjXl9o6pM+WJnPhHCrpBFLOFITTfXZ98f+ac76KLNhwlg/trZE1P8lyJBwmpCfP3FWyi/BHhV7ePokaafRMb3v1YaHhVn1LpKDfEU59W2nNwJu+A7QpIYiPjyKjGiyXZCi1VJUHVaoAmTvf6hKZnaroI2hEvy6Da1VEiZjqYcrynSnwx+duIKlnNIU/68mgljKhPnKhMy+l63faBeQNu3s=',
		libraryLocation:
			'https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.x/build/engine/',
		idLibraryLocation:
			'https://cdn.jsdelivr.net/npm/scandit-web-datacapture-id@6.x/build/engine/'
	},
	datadog: {
		applicationId: '',
		clientToken: '',
		site: 'datadoghq.com',
		service: 'maxsip-sap',
		env: 'development',
		version: '',
		sessionSampleRate: 100,
		sessionReplaySampleRate: 10,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: 'mask-user-input'
	},
	acpContactPhone: '+18773842575',
	genericWebEnrollmentPath: 'generic',
	liveChatKey: '',
	featureFlags: {
		retail: toBoolean('true')
	}
};

function toBoolean(value: string) {
	return value === 'true';
}
